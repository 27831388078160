@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Raleway:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Protest+Revolution&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
$main: #356bb3;
$second: #293148;
$second2: #182d55;

$light: #1babe2;
$bacground: #f1f3f7;
$drop: #596670;
$gr: rgb(55, 58, 61);

$breack-up: (
  "small": "600px",
  "medium": "1164px",
);
$breack-down: (
  "medium": "599px",
  "large": "1163px",
);

@mixin breack_up($size) {
  @media (min-width: map-get($breack-up, $size)) {
    @content;
  }
}
@mixin breack_down($size) {
  @media (max-width: map-get($breack-down, $size)) {
    @content;
  }
}
