.blog_container {
  margin-top: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  img {
    width: 80vw;
    height: 40vh;
  }
  .blog_html {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5rem 5rem 5rem;
  }
}
