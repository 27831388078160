@use "../../App.scss" as *;
.contact_container {
  .contact_back {
    display: flex;
    justify-content: center;
    gap: 10vw;
    padding: 3rem;

    color: $second;
    background-image: url("../../images/cb.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include breack_down(large) {
      flex-direction: column-reverse;
      padding: 2rem;
    }
    .contact_left {
      .contact_title {
        h3 {
          font-size: 3rem;
          margin-bottom: 1rem;
          color: $main;
          @include breack_down(large) {
            text-align: center;
            font-size: 2rem;
          }
        }
        p {
          color: $main;
          font-size: 1.5rem;
          @include breack_down(large) {
            text-align: center;
            font-size: 1rem;
          }
        }
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4rem;
      @include breack_down(large) {
        align-items: center;
      }
      img {
        width: 40vw;
        @include breack_down(large) {
          width: 50vw;
        }
      }
      .contact_info {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .contact_info_div {
          display: flex;
          align-items: center;
          gap: 1rem;
          .contact_icon {
            color: $main;
            width: 2rem;
            height: 2rem;
            @include breack_down(medium) {
              width: 1.3rem;
              height: 1.3rem;
            }
          }
        }
      }
    }
    .contact_right {
      background-color: #fff;
      padding: 1rem;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: space-between;
      .contact_logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 5rem;
        }
        h3 {
          color: $main;
        }
      }
      .contact_form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include breack_down(large) {
          align-items: center;
        }
        input {
          padding: 1rem;
          border-radius: 4px;
          border: none;
          width: 25rem;
          background-color: $bacground;
          @include breack_down(large) {
            width: 80vw;
          }
          &:focus {
            outline: 1px solid $main;
          }
        }
        textarea {
          padding: 1rem;
          border-radius: 4px;
          border: none;
          //   outline: 1px solid $second;
          width: 25rem !important;
          min-height: 5rem !important;
          max-height: 20rem !important;
          background-color: $bacground;
          @include breack_down(large) {
            width: 80vw !important;
          }
          &:focus {
            outline: 1px solid $main;
          }
        }
        .form_btn_container {
          display: flex;
          align-items: center;
          gap: 2rem;
          height: 5rem;
          img {
            width: 3rem;
            height: 3rem;
          }
          button {
            width: fit-content;
            padding: 1rem 2rem;
            border: none;
            outline: 1px solid $main;
            background-color: $main;
            color: #fff;
            border-radius: 5px;
            font-weight: 500;
            transition: 0.5s;
            cursor: pointer;
            @include breack_down(large) {
              padding: 0.5rem 1rem;
            }
            &:hover {
              background-color: transparent;
              color: $main;
            }
          }
        }
      }
    }
  }
}
.contact_div {
  position: relative;
  p {
    position: absolute;
    color: rgb(165, 1, 1);
  }
}
.outline_red {
  input {
    outline: 1px solid rgb(165, 1, 1) !important;
  }
}
