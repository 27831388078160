@use "../../App.scss" as *;
.faq_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw 0;
  margin-top: 10rem;
  @include breack_up(medium) {
    min-height: 50vh;
    min-height: 50vh;
  }
  @include breack_down(large) {
    margin-top: 5rem;
  }
  .faq_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 60%;
    text-align: center;
    @include breack_down(large) {
      width: 90%;
    }
  }
}
.faq_map {
  margin: 5rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 5rem;
  width: 90vw;
  align-items: center;
  @include breack_down(large) {
    display: none !important;
  }
  .faq_top {
    display: flex;
    justify-content: center;
    width: fit-content;
    border: 1px solid $light;
    width: 100%;
    border-radius: 10px;
    .faq_top_item {
      padding: 1rem;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.6s;
      color: $second;
      &:hover {
        background-color: #b2f2fa;
      }
    }
  }
  .faq_top_inner {
    display: none;
  }
  .faq_bottom {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .faq_item {
      width: 100%;
      // min-width: 33%;
      padding: 1rem;
      // border: 1px solid $light;
      border-radius: 10px;
      transition: 0.5s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: fit-content;
    }
    .display_none {
      display: none !important;
    }

    .sub_category {
      margin: 5px 0;
      cursor: pointer;
      color: $second;

      &:hover {
        color: $light;
      }
    }
  }
}
.faq_q_container {
  h4 {
    margin: 10px 0 10px 15px;
    cursor: pointer;
    color: $second;
    transition: 0.6s;
    &:hover {
      color: $light;
    }
  }
  p {
    color: $gr;
    margin: 10px 0 10px 30px;
  }
}
.faq_top_item_active {
  background-color: $light;
  // border-radius: 20px;
}

.opacity_0 {
  opacity: 0 !important;
  pointer-events: none;
}

.faq_mobile {
  margin: 2rem 0;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // width: fit-content;
  width: 100%;
  justify-content: center;
  align-items: center;
  .faq_mobile_sub {
    h4 {
      margin: 10px 0px 10px 15px;
    }
    p {
      margin: 10px 0px 10px 30px;
    }
  }
  @include breack_up(medium) {
    display: none !important;
  }
  .faq_item_mobile {
    width: 100%;
  }
  .faq_top_item_mobile {
    margin: 0.5rem 0;
    padding: 0.5rem;
    width: 100%;
    outline: 1px solid $light;
  }
  .faq_top_item_active_mobile {
    background-color: $light;
  }
  .faq_mobile_quest {
    margin-left: 5px;
  }
}

.faq_aqtive_color {
  color: $light !important;
}
