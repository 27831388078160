@use "../../App.scss" as *;
.product_container {
  min-height: 100vh;
  padding-top: 10rem;
  @include breack_down(large) {
    padding-top: 7rem;
  }
}
.product_hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  h2 {
    color: $main;
    font-size: 3rem;
    @include breack_down(large) {
      font-size: 2rem;
    }
  }
  h3 {
    color: $main;
    font-size: 1.2rem;
    @include breack_down(large) {
      font-size: 1rem;
    }
  }
  p {
    width: 70%;
    color: $second;
    @include breack_down(large) {
      font-size: 0.75rem;
      width: 90%;
    }
  }
  button {
    border: none;
    padding: 1rem 1.5rem;
    background-color: $light;
    color: #fff;
    border-radius: 10px;
    margin-top: 2rem;
    cursor: pointer;
    outline: 1px solid $light;
    transition: 0.5s;
    @include breack_down(large) {
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
    }
    &:hover {
      background-color: transparent;
      color: $light;
    }
  }
}
.solutions {
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 4rem;
  background-color: #fff;
  @include breack_down(large) {
    flex-direction: column-reverse;
  }
  img {
    width: 5rem;
    height: 5rem;
  }
  .solutions_left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    @include breack_down(large) {
      grid-template-columns: 1fr;
    }
    .solution_item {
      height: 15rem;
      background-color: #fff;
      border-radius: 10px;
      padding: 1.5rem;

      box-shadow: 0 0 20px #031c6e62;
    }
    .margin_t {
      margin-top: 2rem;
    }
  }
  .solutions_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    text-align: center;
    h3 {
      color: $main;
    }
    p {
      color: $second;
    }
    button {
      border: none;
      padding: 0.5rem 1rem;
      background-color: $light;
      color: #fff;
      border-radius: 10px;
      margin-top: 2rem;
      cursor: pointer;
      outline: 1px solid $light;
      transition: 0.5s;
      &:hover {
        background-color: transparent;
        color: $light;
      }
    }
  }
}
.process {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .process_text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 70%;
    text-align: center;
    margin-bottom: 2rem;
    @include breack_down(large) {
      width: 90%;
    }
    h3 {
      color: $main;
    }
    p {
      color: $second;
    }
  }
  .process_body {
    display: flex;
    align-items: center;
    gap: 3rem;
    @include breack_down(large) {
      flex-direction: column;
    }
    img {
      @include breack_down(large) {
        width: 100%;
      }
    }
    .process_left {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      p {
        margin-left: 1rem;
      }
    }
  }
}
.benefits {
  background-color: #fff;
  padding: 5rem 0;
  h3 {
    text-align: center;
    color: $main;
  }
  .benefits_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    margin-top: 5rem;
    @include breack_down(large) {
      gap: 1rem;
    }
    .benefits_item {
      display: flex;
      gap: 1rem;
      width: 35%;
      background-color: $bacground;
      padding: 2rem;
      border-radius: 10px;
      min-height: 10rem;
      box-shadow: 0 0 20px #031c6e49;
      @include breack_down(large) {
        width: 90%;
      }
      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
.sticky_nav {
  margin-top: 4rem;
  position: sticky;
  width: fit-content;
  top: 10rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 1;
  border-radius: 10px;
  outline: 1px solid $main;
  overflow: hidden;
  @include breack_down(large) {
    left: 0%;
    transform: translateX(0%);
    width: 100%;
    top: 5rem;
    display: flex;
    justify-content: space-between;
  }
  .sticky_btn {
    @include breack_down(large) {
      font-size: 10px;
      // padding: 0.4rem;
      width: 100%;
    }
    border: none;
    background-color: transparent;
    padding: 0.4rem 1rem;
    cursor: pointer;
    color: #fff;
    transition: 0.5s;

    font-weight: 600;
    color: $main;
    @include breack_up(medium) {
      &:hover {
        background-color: $main;
        color: #fff;
      }
    }
  }
  .sticky_btn_active {
    background-color: $main;
    color: #fff;
  }
}
.product_calc {
  display: flex;
  justify-content: space-between;
  padding: 3rem;
  .product_clac_lef {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .product_calc_input {
      display: flex;
      flex-direction: column;
      // align-items: center;
      gap: 10px;
      select {
        width: 10rem;
        padding: 0.5rem;
        border: 1px solid $main;
        border-radius: 5px;
        background-color: #fff;
      }
      input {
        width: 10rem;
        padding: 0.5rem;
        border: 1px solid $main;
        border-radius: 5px;
      }
      input:focus {
        outline: 1px $main solid;
      }
    }
    .product_calc_top,
    .product_calc_bottom {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }
    .product_calc_bottom {
      .product_calc_input {
        width: 30%;
      }
      input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid $main;
        border-radius: 5px;
      }
      input:focus,
      select:focus {
        outline: 1px $main solid;
      }
    }
    button {
      background-color: $main;
      padding: 0.7rem;
      color: #fff;
      border: none;
      cursor: pointer;
      transition: 0.5s;
      border-radius: 5px;
      &:hover {
        outline: 1px solid $main;
        background-color: transparent;
        color: $main;
      }
    }
  }
  .product_calc_riht {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 30%;
    h2 {
      text-align: center;
      color: $main;
    }
    .product_calc_div {
      display: flex;
      gap: 2rem;
      align-items: center;
      justify-content: space-between;
      h3 {
        color: $main;
      }
    }
    .product_calc_right_buttons {
      display: flex;
      justify-content: space-between;
      button {
        background-color: $main;
        padding: 0.7rem;
        color: #fff;
        border: none;
        cursor: pointer;
        transition: 0.5s;
        border-radius: 5px;
        width: 45%;
        &:hover {
          outline: 1px solid $main;
          background-color: transparent;
          color: $main;
        }
      }
    }
  }
}
