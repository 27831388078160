@use "../../App.scss" as *;
.calc_container {
  margin-top: 10rem;
  padding: 2rem;
  .calc_title {
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    gap: 2rem;
  }
  .calc_nav {
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .calc_button {
      display: flex;
      align-items: center;
      width: 30%;
      justify-content: space-between;
      button {
        width: 15rem;
        border: none;
        padding: 0.5rem 0;
        border-radius: 5px;
        outline: 1px solid $main;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
          background-color: $light;
          outline: 1px solid $light;
          color: #fff;
        }
      }
    }
  }
}

.active_calc_btn {
  background-color: $main;
  color: #fff;
}

.calc_currency {
  display: flex;
  gap: 3rem;
  align-items: center;
}
.calc_cs {
  display: flex;
  gap: 2rem;
  button {
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    outline: 1px solid $main;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background-color: $light;
      outline: 1px solid $light;
      color: #fff;
    }
  }
}
.calc_inp_graph {
  display: flex;
  margin-top: 5rem;
  justify-content: space-between;
  .calc_inputs {
    width: fit-content;
    .calc_f_input {
      display: flex;
      p {
        width: 15rem;
      }
      .calc_f_input_left {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 1rem;
        h4 {
          padding-right: 1rem;
        }
        .calc_inp_container {
          display: flex;
          border-right: 1px solid $main;
          padding-right: 1rem;
          gap: 1rem;
          align-items: center;
          input {
            border: none;
            border-bottom: 1px solid $main;
            outline: none;
            padding: 10px;
            background-color: transparent;
            &:focus {
              border-bottom: 1px solid $light;
            }
          }
        }
      }
      .calc_f_input_right {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-left: 1rem;
        .calc_inp_container {
          input {
            border: none;
            border-bottom: 1px solid $main;
            outline: none;
            padding: 10px;
            background-color: transparent;
            &:focus {
              border-bottom: 1px solid $light;
            }
          }
        }
      }
    }
    .calc_s_input {
      display: flex;
      gap: 1rem;
      .calc_s_input_left {
        p {
          width: 15rem;
          margin-top: 1rem;
        }
        .calc_inp_container {
          display: flex;
          border-right: 1px solid $main;
          padding-right: 1rem;
          gap: 1rem;
          align-items: center;
          input {
            margin-top: 1rem;
            border: none;
            border-bottom: 1px solid $main;
            outline: none;
            padding: 10px;
            background-color: transparent;
            &:focus {
              border-bottom: 1px solid $light;
            }
          }
        }
      }
      .calc_s_input_right {
        margin-top: 1rem;
        .calc_inp_container {
          input {
            border: none;
            border-bottom: 1px solid $main;
            outline: none;
            padding: 10px;
            background-color: transparent;
            &:focus {
              border-bottom: 1px solid $light;
            }
          }
        }
      }
    }
    .calc_inp_btns {
      display: flex;
      justify-content: space-around;
      margin: 2rem 0;
      button {
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.5s;
        background-color: $light;
        outline: 1px solid $light;
        color: #fff;
        &:hover {
          background-color: #fff;
          color: $light;
        }
      }
    }
  }
  .calc_graph {
    border: 1px solid $main;
    padding: 1rem;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    width: 40%;
    .calc_graph_top {
      display: flex;
      justify-content: space-between;
      height: fit-content;
      width: 100%;
      .graph_middle_line {
        width: 1px;
        background-color: $main;
      }
    }
    .graph_text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      width: 100%;
      p {
        width: 80%;
        text-align: center;
      }
    }

    button {
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.5s;
      background-color: $light;
      outline: 1px solid $light;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: $light;
      }
    }
  }
}
