@use "../../App.scss" as *;
.test {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.test_container {
  margin: 4rem 0;
  width: 80%;
  .test_head {
    width: 100%;
    .test_image_container {
      width: 12%;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-bottom: 3px solid rgb(146, 146, 146);
      cursor: pointer;
      img {
        width: 85%;
        height: 5rem;
        object-fit: cover;
        @include breack_down(large) {
          width: 95%;
        }
      }
      &:focus {
        outline: none !important;
      }
    }
  }
  .test_body {
    display: flex;
    justify-content: space-around;
    margin-top: 3rem;
    @include breack_down(large) {
      flex-direction: column;
      // margin-top: 1rem;
      gap: 1rem;
    }
    .test_body_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      h4 {
        font-size: 1.7rem;
        font-weight: 400;
        color: #182d55;
        width: 80%;
        @include breack_down(large) {
          width: 100%;
          text-align: center;
          font-size: 1rem;
        }
      }
      p {
        font-size: 1.2rem;
        color: #1babe2;
        @include breack_down(large) {
          font-size: 1rem;
          text-align: center;
        }
      }
    }
    .test_body_info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      h3 {
        letter-spacing: 3px;
        color: #7d95a5;
      }
      p {
        color: #182d55;
      }
      img {
        width: 15rem;
        @include breack_down(large) {
          width: 8rem;
        }
      }
    }
  }
}
.active_testimonial {
  border-bottom: 3px solid rgb(102, 156, 226) !important ;
}
.slick-slide {
  margin: 0 !important;
  padding: 0 !important;
}
.test_flex {
  display: flex;
  justify-content: center;
}
.slick-dots {
  @include breack_down(large) {
    top: 100%;
  }
}
.slick-prev:before,
.slick-next:before {
  color: $main !important;
}
