@use "../../App.scss" as *;

.footer_container {
  background-color: $second;
  display: flex;
  padding: 3rem;
  justify-content: space-between;

  @include breack_down(large) {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    padding: 1rem;
  }
  .footer_logo {
    cursor: pointer;
    img {
      width: 10rem;
    }
  }
  .footer_links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    @include breack_down(large) {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
    a {
      cursor: pointer;
      transition: 0.5s;
      text-decoration: none;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      &:hover {
        color: $main;
      }
    }
  }
  .footer_media {
    display: flex;
    gap: 1rem;
    svg {
      color: #fff;
      width: 3rem;
      height: 3rem;
      cursor: pointer;
      transition: 0.5s;
      @include breack_down(medium) {
        width: 1.5rem;
        height: 1.5rem;
      }
      &:hover {
        color: $main;
      }
    }
  }
}
