@use "../../App.scss" as *;
.acc_container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  // background-color: #fff;
  padding-top: 3rem;
  @include breack_down(large) {
    gap: 1rem;
  }
  h2 {
    text-align: center;
  }
  .acc_slider_container {
    overflow: hidden;
    position: relative;

    h2 {
      margin: 1rem 0;
    }
    .acc_icon {
      position: absolute;
      font-size: 3rem;
      color: $main;
      z-index: 3;
      transition: 0.5s;
      transform: translateY(-50%);
      cursor: pointer;
      &:hover {
        color: $light;
      }
    }
    .acc_left {
      top: 50%;
      left: 10px;
    }
    .acc_right {
      top: 50%;
      right: 10px;
    }
  }
  .acc_slider {
    display: flex;
    align-items: center;
    gap: 5vw;
    position: relative;
    transition: 0.5s;

    img {
      width: 7vw;
      height: fit-content;
      object-fit: cover;
      user-select: none;
    }
  }
  .acc_dark {
    background-color: $second;
    display: flex;
    justify-content: center;
    padding: 2rem;
    gap: 10rem;
    @include breack_down(large) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding: 3vw;
      width: 100%;
      overflow: hidden;
    }
    .acc_dark_info {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 3rem;
      max-width: 60%;
      @include breack_down(large) {
        max-width: 100%;
        // padding: 0 1rem;
      }

      h3 {
        font-size: 1.7rem;
        @include breack_down(large) {
          text-align: center;
          font-size: 1.2rem;
        }
      }
      .acc_dark_numbers {
        display: flex;
        gap: 1rem;
        @include breack_down(large) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
        h4 {
          font-size: 1.3rem;
          @include breack_down(large) {
            font-size: 1rem;
          }
        }
      }
      .border_right {
        border-right: 1px solid #fff;
        padding-right: 1rem;
      }
      .border_right_none {
        @include breack_down(large) {
          border-right: none;
        }
      }
    }
    .acc_dark_globe {
      overflow: hidden;
      canvas {
        width: 30vw;
      }

      .globe {
        width: 100%;
      }
      position: relative;

      .dark_car {
        width: 100%;
        height: 100%;
        animation: rotate 30s infinite linear;
        transform-origin: 50% 50%;
        .dark_car_item {
          position: absolute;
          display: flex;
          gap: 1rem;
          color: $main;
          // animation: rotate2 30s infinite linear;
          // transform-style: preserve-3d;
          // transform-origin: 50% 50%;
          p {
            color: #fff;
          }
        }
        .dark_car_item_1 {
          top: 0%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .dark_car_item_2 {
          top: 25%;
          left: 100%;
          transform: translate(-50%, -50%);
        }

        .dark_car_item_3 {
          top: 75%;
          left: 100%;
          transform: translate(-50%, -50%);
        }

        .dark_car_item_4 {
          top: 25%;
          left: 0%;
          transform: translate(-50%, -50%);
        }

        .dark_car_item_5 {
          top: 75%;
          left: 0%;
          transform: translate(-50%, -50%);
        }
        .dark_car_item_6 {
          top: 100%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

/* acc.scss */
.carousel-container {
  position: relative;
  overflow: hidden;
}

.carousel-item-padding-40-px {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item-padding-40-px img {
  max-width: 100%;
  max-height: 4rem;
  display: block;
}

.carousel-container {
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.carousel-track {
  display: flex;
  white-space: nowrap;
}

.carousel-image {
  object-fit: cover;
  margin: 0 2rem;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  height: 3rem;
  margin-right: 0;
  &:hover {
    transform: scale(1.05);
  }
}
