@use "../../App.scss" as *;
.pop_container {
  position: fixed;
  top: 0vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -100;
}
.top_z {
  z-index: 11111111 !important;
}
.pop_close {
  position: absolute;
  top: -2rem;
  right: -2rem;
  z-index: 2;
  cursor: pointer;
  transition: 0.5s;
  @include breack_down(large) {
    right: 0;
  }
  &:hover {
    scale: 1.05;
  }
  img {
    width: 1.5rem;
  }
}
.pop_back {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.842);
}
.pop_form {
  position: relative;
  transition: 0.5s;
  top: -120vh;
  z-index: 2;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .contact_logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 5rem;
    }
    h3 {
      color: $main;
    }
  }
  .contact_form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @include breack_down(large) {
      align-items: center;
    }
    input {
      padding: 1rem;
      border-radius: 4px;
      border: none;
      width: 25rem;
      background-color: $bacground;
      @include breack_down(large) {
        width: 80vw;
      }
      &:focus {
        outline: 1px solid $main;
      }
    }
    textarea {
      padding: 1rem;
      border-radius: 4px;
      border: none;
      //   outline: 1px solid $second;
      width: 25rem !important;
      min-height: 5rem !important;
      max-height: 20rem !important;
      background-color: $bacground;
      @include breack_down(large) {
        width: 80vw !important;
      }
      &:focus {
        outline: 1px solid $main;
      }
    }
    .form_btn_container {
      display: flex;
      align-items: center;
      gap: 2rem;
      height: 5rem;
      img {
        width: 3rem;
        height: 3rem;
      }
      button {
        width: fit-content;
        padding: 1rem 2rem;
        border: none;
        outline: 1px solid $main;
        background-color: $main;
        color: #fff;
        border-radius: 5px;
        font-weight: 500;
        transition: 0.5s;
        cursor: pointer;
        @include breack_down(large) {
          padding: 0.5rem 1rem;
        }
        &:hover {
          background-color: transparent;
          color: $main;
        }
      }
    }
  }
}
.top_0 {
  top: 0 !important;
}
