@use "../../App.scss" as *;
.cp_container {
  gap: 10vw;
  height: fit-content;
  img {
    width: 5rem;
  }
  .cp_left {
    width: 50%;

    .cp_left_info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    @include breack_down(large) {
      width: 100%;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }
    .email_list {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      @include breack_down(large) {
        gap: 0.5rem;
      }
    }
  }
  .cp_right {
    padding: 1.5rem;
    background-color: #dcf0fd;
    border-radius: 5px;
    height: fit-content;
    position: relative;
    top: -15rem;
    @include breack_down(large) {
      top: 0;
    }
    .cp_form_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      color: $main;
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 1.4rem;
      textarea {
        max-width: 30rem;
        max-height: 5rem;
        padding: 0.4rem;
        border: none;
        background-color: #fff;
        border-radius: 3px;
        @include breack_down(large) {
          width: 100%;
          max-width: 100%;
        }
        &:focus {
          outline: 1px solid $main;
        }
      }
      button {
        width: fit-content;
        padding: 0.5rem 1rem;
        border: none;
        outline: 1px solid $main;
        background-color: $main;
        color: #fff;
        border-radius: 5px;
        font-weight: 500;
        transition: 0.5s;
        cursor: pointer;
        @include breack_down(large) {
          padding: 0.5rem 1rem;
        }
        &:hover {
          background-color: transparent;
          color: $main;
        }
      }
      .cp_input_div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        input {
          width: 30rem;
          padding: 0.4rem;
          border: none;
          background-color: #fff;
          border-radius: 3px;
          @include breack_down(large) {
            width: 100%;
          }
          &:focus {
            outline: 1px solid $main;
          }
        }
      }
      .cp_radio {
        h3 {
          margin-bottom: 0.5rem;
          font-weight: 500;
        }
        .cp_radio_input {
          display: flex;
          gap: 0.5rem;
        }
      }
    }
  }
}
.cp_info_icons {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  span {
    font-weight: 500;
  }
}
.cp_back_top {
  padding: 13rem 10vw 3rem 10vw;
  background-image: url("../../images/cb.svg");
  @include breack_down(large) {
    padding: 7rem 1rem 2rem 1rem;
  }
  .cp_left_title {
    width: 50%;
    @include breack_down(large) {
      width: 100%;
    }
    h1 {
      font-size: 44px;
      color: #fff;
      margin-bottom: 1rem;
      @include breack_down(large) {
        font-size: 2rem;
        text-align: center;
      }
    }
    p {
      color: #fff;
      @include breack_down(large) {
        text-align: center;
        font-size: 0.8rem;
      }
    }
  }
}
.cp_bottom {
  display: flex;
  padding: 5rem 10vw 0 10vw;
  justify-content: space-between;
  background-color: #ffffff;
  p {
    font-size: 1.2rem;
  }
  span {
    font-size: 1.3rem;
  }
  @include breack_down(large) {
    flex-direction: column-reverse;
    padding: 2rem 1rem;
    gap: 2rem;
  }
}
