@use "../../App.scss" as *;
.blogs_container {
  overflow: hidden;
  padding: 3rem 0;
  @include breack_down(large) {
    padding: 2rem;
  }
  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .blogs_content {
    padding: 0rem 2rem 1rem 2rem;
    // height: 50vw;
    @include breack_down(large) {
      padding: 0;
    }
    cursor: pointer;
    .blog {
      position: relative;
      display: block;
      overflow: hidden;
      // min-width: 25rem;
      min-height: 30vh;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      img {
        object-fit: cover;
        height: 60%;
      }
      h3 {
        text-align: center;
      }
      .blog_hover {
        background: rgba(53, 107, 179, 0.63);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(12.9px);
        -webkit-backdrop-filter: blur(12.9px);
        border: 1px solid rgba(53, 107, 179, 0.3);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        color: #fff;
        transition: 0.5s;
        top: -100vh;
        @include breack_down(large) {
          display: none !important;
        }
        p {
          text-align: center;
        }
        a {
          text-decoration: none;
          color: $main;
          padding: 10px 15px;
          background-color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.5s;
          &:hover {
            opacity: 0.5;
          }
        }
      }
      .blog_click {
        background: rgba(53, 107, 179, 0.63);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(12.9px);
        -webkit-backdrop-filter: blur(12.9px);
        border: 1px solid rgba(53, 107, 179, 0.3);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        color: #fff;
        transition: 0.5s;
        top: -100vh;
        @include breack_up(medium) {
          display: none !important;
        }
        p {
          text-align: center;
        }
        a {
          color: $main;
          text-decoration: none;
          padding: 10px 15px;
          background-color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.5s;
        }
      }
    }
  }
}
.blog_click_container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  @include breack_up(medium) {
    display: none !important;
  }
  .blog_icon {
    transition: 0.5s;
    width: 2rem;
    height: 2rem;
    color: $light;
  }
  .blog_icon_0 {
    width: 0;
  }
}
.top_0 {
  top: 0 !important;
}
.slick-slide {
  padding: 0 27px;
  @include breack_down(large) {
    padding: 0;
  }
}
.slick-dots {
  bottom: -4rem !important;
}
.blogs_title_container {
  position: relative;
  .blog_see {
    position: absolute;
    right: 0;
    top: 50%;
    translate: 0 -50%;
    padding-right: 1rem;
    text-decoration: none;
    color: $main;
    font-weight: 600;
  }
}
