@use "../../App.scss" as *;
.products_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 20px;
  padding: 1rem 0;
  background-color: #fff;
  width: 100%;
  h2 {
    @include breack_down(large) {
      text-align: center;
      font-size: 1.2rem;
    }
  }
  .products_items_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    gap: 3rem;
    width: 100%;
    @include breack_down(large) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      justify-content: center;
      align-items: center;
    }
    @include breack_down(large) {
      grid-template-columns: 1fr;
    }
    .product_single {
      max-width: 25vw;
      @include breack_down(large) {
        max-width: 40vw;
      }
      @include breack_down(medium) {
        max-width: 80vw;
        margin: 0;
      }
    }
    .product {
      @include breack_down(large) {
        width: 40vw;
      }
      @include breack_down(medium) {
        width: 80vw;
        margin: 0;
        box-shadow: 0 0 0px #02205e52;
        border: 1px solid #02205e52;
      }
      background-color: #fff;
      margin: 1rem 2rem;
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      border-radius: 15px;
      box-shadow: 0 0 20px #02205e52;
      transition: 0.5s;
      &:hover {
        @include breack_up(medium) {
          transform: scale(1.04);
        }
      }
      img {
        width: 100%;
        height: 20vh;
        object-fit: cover;
      }
      .products_info {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include breack_down(large) {
          gap: 0.4rem;
        }
        h3 {
          color: $second;
          font-size: 18px;
          text-align: center;
          @include breack_down(large) {
            font-size: 16px;
          }
        }
        p {
          color: $gr;
          font-weight: 500;
          text-align: center;
          font-size: 14px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          @include breack_down(large) {
            font-size: 14px;
          }
        }
        a {
          padding: 0.5rem;
          background-color: $main;
          border: none;
          color: #fff;
          outline: 1px solid $main;
          font-weight: 600;
          cursor: pointer;
          transition: 0.5s;
          text-align: center;
          text-decoration: none;
          @include breack_down(large) {
            font-size: 14px;
          }
          &:hover {
            background-color: #fff;
            color: $main;
          }
        }
      }
    }
  }
}
.product_btns {
  display: flex;
  gap: 1rem;
  cursor: pointer;
  background-color: $bacground;
  p {
    padding: 1rem 2rem;
    font-weight: 600;
    transition: 0.5s;
    @include breack_down(large) {
      padding: 0.5rem 1rem;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .active_product {
    color: #fff;
    background-color: $light;
  }
}
.slider_container {
  width: 90%;
  @include breack_down(large) {
    width: 100%;
    padding: 0 1.9rem;
    overflow: hidden;
  }
}
